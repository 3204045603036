import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


var orbit = new Foundation.Orbit($('#main-top-slider'), {
    animInFromLeft: 'fade-in',
    animInFromRight: 'fade-in',
    animOutToLeft: 'fade-out',
    animOutToRight: 'fade-out',
    timerDelay: 4000,
    pauseOnHover: true,
    navButtons: true,
    bullets: true,
    nextClass: 'bk-orbit-arrows__right',
    prevClass: 'bk-orbit-arrows__left',
    boxOfBullets: 'bk-orbit-bullets',
    // autoPlay: false,
});

var orbit = new Foundation.Orbit($('#portfolio-slider'), {
    animInFromLeft: 'fade-in',
    animInFromRight: 'fade-in',
    animOutToLeft: 'fade-out',
    animOutToRight: 'fade-out',
    timerDelay: 4000,
    pauseOnHover: true,
    navButtons: true,
    // bullets: true,
    nextClass: 'bk-portfolio-nav__right',
    prevClass: 'bk-portfolio-nav__left',
    // boxOfBullets: 'bk-orbit-bullets',
    // autoPlay: false,
});

// $('.formModal__switch').on('click', function(){
//     console.log( $(this).find('input').is(':checked') );
//     $('.formModal__additional-info').addClass('open');
// });

$('.formModal__switch').on('change', 'input', function(){
    $('.formModal__additional-info').toggleClass('open');
});

$('.formModal__secret').attr('value','rwqeropipohjhksafdnb');

$('#bk-contact-form').on('submit', function(e){
   e.preventDefault();
   let formData = $( this ).serialize();


    $.ajax({
        method: "POST",
        url: "assets/scripts/bk_script.php",
        data: formData,
    })
        .done(function( msg ) {
            $('.bk-contact-form__message').text( msg );
        })
        .fail(function() {
            $('.bk-contact-form__message').text( "Błąd przesłania formularza!" );
        })


});

$('.toggle-nav').on('click', function() {
   $(this).toggleClass('is-active');
   $('.top-nav').toggleClass('open');
});